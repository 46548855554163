import "./index.scss";

import React from "react";

import { Alert } from "react-bootstrap";
import { Table } from "../../commonTable/Table";
import getNoun from "../../getNoun";
import Checkbox from "../../inputs/checkbox";
import MultiSelectList from "../../inputs/multiSelectList";
import InputText from "../../inputs/text";
import IMAGES from "../../../images/iconSet";
import { LimitTable } from "./LimitTable";

const importedDoctorsColumns = [
  {
    key: "email",
    label: "Email",
    dataKey: "email",
    width: "160px",
  },
  {
    key: "error",
    label: "Ошибки",
    dataKey: "error",
    width: "160px",
    style: { color: "#FF837B" },
  },
];

const removeNullFromArrayValue = (array) => {
  return array.map((a) => ({ ...a, value: a.value.replace("null", "") }));
};

export class UserResearches extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      researchId: 0,
      moderators: [],
      userLimits: [],
      admins: [],
      monitors: [],
      doctors: [],
      users: [],
      showSuccessImportAlert: false,
      addedDoctorsCount: 0,
      skippedDoctorsTableItems: [],
      importDoctorsError: "",
      limitsAlert: "",
      limitsUpdatedAlert: "",
    };

    if (props.data) {
      this.state.data = {
        admins: props.data.admins ? props.data.admins : [],
        moderators: props.data.moderators ? props.data.moderators : [],
        monitors: props.data.monitors ? props.data.monitors : [],
        doctors: props.data.doctors ? props.data.doctors : [],
        requestorRepresentatives: props.data.requestorRepresentatives
          ? props.data.requestorRepresentatives
          : [],
        doctorsLimit: props.data.doctorsLimit ? props.data.doctorsLimit : "",
        moderate: !!props.data.moderate,
      };
    }
    if (props.data.id) {
      this.state.researchId = props.data.id;
    }

    this.uploadExcel = React.createRef();
  }

  onChange = () => {
    console.log(this.state);
    this.props.onChange(this.state.data);
  };

  getLimits = () => {
    return fetch(global.apiUrl + `/research/limits/${this.state.researchId}`)
      .then((response) =>
        response.text().then((text) => {
          this.setState({ userLimits: JSON.parse(text) });
        })
      )
      .catch((err) => console.log(err));
  };

  componentDidMount = () => {
    console.log("this.state", this.state);
    fetch(global.apiUrl + "/data/users", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: JSON.parse(sessionStorage.getItem("token")),
      }),
    })
      .then((response) => {
        try {
          response.text().then((text) => {
            const users = JSON.parse(text);

            const moderators = [];
            const admins = [];
            const monitors = [];
            const doctors = [];
            const defaultUsers = [];

            users.forEach((user, i) => {
              const tmpUser = {
                id: user.id,
                value: `<div style="display:flex; flex-direction: column;">
                  <div>
                ${user.id} ${user.surname} ${user.name} ${user.patronymic ?? ""}
                </div>
                <span style="font-size:.95rem; color: #c6c6c6">${
                  user.email
                }</span>
                </div>
                `,
              };
              if (parseInt(user.role) === 2) moderators.push(tmpUser);
              if (parseInt(user.role) === 4) admins.push(tmpUser);
              if (parseInt(user.role) === 3) monitors.push(tmpUser);
              if (parseInt(user.role) === 1) doctors.push(tmpUser);
              if (parseInt(user.role) === 0) defaultUsers.push(tmpUser);
            });

            const state = {
              ...this.state,
              moderators: removeNullFromArrayValue(moderators),
              monitors: removeNullFromArrayValue(monitors),
              admins: removeNullFromArrayValue(admins),
              doctors: removeNullFromArrayValue(doctors),
              users: removeNullFromArrayValue(defaultUsers),
            };
            this.setState(state);
          });
        } catch {
          console.error("Ошибка при прочтении данных");
        }
      })
      .catch((err) => console.log(err));

    this.getLimits();
  };

  onUsersChange = (data, obj) => {
    this.state.data[obj] = data;
    this.setState(this.state, this.onChange);
  };

  onModerationChange = (e) => {
    this.state.data.moderate = e.target.checked;
    if (e.target.checked === false) {
      this.state.data.moderators = [];
    }
    this.setState(this.state, this.onChange);
  };

  doctorLimitChange = (e) => {
    const string = [...e.target.value];
    let limit = "";
    string.forEach((char) => {
      if ("0123456789".indexOf(char) > -1) {
        limit += char;
      }
    });
    this.state.data.doctorsLimit = limit;
    this.setState(this.state);
  };

  onUploadExcel = () => {
    this.uploadExcel.current.click();
  };

  onUploadExelChange = (e) => {
    this.setState({
      showSuccessImportAlert: false,
      addedDoctorsCount: 0,
      skippedDoctorsTableItems: [],
      importDoctorsError: "",
      limitsAlert: "",
      limitsUpdatedAlert: "",
    });
    const files = [...e.target.files];

    const formData = new FormData();

    formData.append("importFile", files[0]);
    formData.append("research", this.state.researchId);

    this.uploadExcel.current.value = "";

    fetch(global.apiUrl + "/research/importDoctors", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "ok") {
          const { response } = data;

          if (response.add.length === 0 && response.skip.length === 0) {
            this.setState({
              importDoctorsError: "Произошла ошибка. Проверьте формат файла",
            });
          }

          if (response.add.length > 0) {
            const newDoctors = response.add.filter(
              (id) => !this.state.data.doctors.find((i) => i === id)
            );
            if (newDoctors.length > 0) {
              this.onUsersChange(
                [...this.state.data.doctors, ...newDoctors],
                "doctors"
              );
              this.setState({
                addedDoctorsCount: newDoctors.length,
                showSuccessImportAlert: true,
              });
            } else if (newDoctors.length === 0 && response.add.length > 0) {
              // this.setState({
              //   importDoctorsError: "Найденые пользователи уже прикреплены",
              // });
              this.setState({ limitsAlert: "Данные успешно обновлены" });
            }
            this.getLimits();
          }
          if (response.skip.length > 0) {
            const items = response.skip.map((item) => ({
              email: item,
              error: "Пользователь не найден",
            }));
            this.setState({
              skippedDoctorsTableItems: items,
              importDoctorsError: "Есть ошибки",
            });
          }
        } else {
          this.setState({
            importDoctorsError: "Произошла ошибка. Проверьте формат файла",
          });
        }
      });
  };

  handleDeleteLimit = (limit) => {
    this.setState({ limitsAlert: "", limitsUpdatedAlert: "" });
    fetch(global.apiUrl + "/research/limits/", {
      method: "DELETE",
      body: JSON.stringify({
        userId: limit.userId,
        research: this.state.researchId,
      }),
    }).then(() => this.getLimits());
  };

  handleChangeLimit = (updatedLimit) => {
    this.setState({ limitsAlert: "", limitsUpdatedAlert: "" });
    fetch(global.apiUrl + "/research/limits/", {
      method: "POST",
      body: JSON.stringify({
        userId: updatedLimit.userId,
        research: this.state.researchId,
        formsLimit: updatedLimit.formsLimit,
      }),
    }).then(() =>
      this.getLimits().then(() =>
        this.setState({ limitsUpdatedAlert: "Лимиты обновлены" })
      )
    );
  };

  render() {
    return (
      <div className="user--researches">
        <div className="user--researches--card">
          <ul className="user--researches--form">
            <li className="user--researches--form--item">
              <div className="item--text semi__bold">
                <Checkbox
                  value={this.state.data.moderate}
                  onChange={this.onModerationChange}
                >
                  Анкеты проходят модерации перед принятием
                </Checkbox>
              </div>
            </li>
            {this.state.data.moderate && (
              <li className="user--researches--form--item">
                <div className="item--text semi__bold">
                  <span>Выбор модератора</span>
                </div>
                <MultiSelectList
                  key={this.state.moderators.length}
                  data={this.state.moderators}
                  selected={this.state.data.moderators}
                  count={this.state.data.moderators.length}
                  onChange={(data) => this.onUsersChange(data, "moderators")}
                />
              </li>
            )}
            <li className="user--researches--form--item">
              <div className="item--text semi__bold">
                <span>Выбор администратора</span>
              </div>
              <MultiSelectList
                key={this.state.admins.length}
                data={this.state.admins}
                selected={this.state.data.admins}
                count={this.state.data.admins.length}
                onChange={(data) => this.onUsersChange(data, "admins")}
              />
            </li>
            <li className="user--researches--form--item">
              <div className="item--text semi__bold">
                <span>Выбор монитора</span>
              </div>
              <MultiSelectList
                key={this.state.monitors.length}
                data={this.state.monitors}
                selected={this.state.data.monitors}
                count={this.state.data.monitors.length}
                onChange={(data) => this.onUsersChange(data, "monitors")}
              />
            </li>
            <li className="user--researches--form--item">
              <div className="item--text semi__bold">
                <span>Представитель заказчика</span>
              </div>
              <MultiSelectList
                key={this.state.users.length}
                data={this.state.users}
                selected={this.state.data.requestorRepresentatives}
                count={this.state.data.requestorRepresentatives.length}
                onChange={(data) =>
                  this.onUsersChange(data, "requestorRepresentatives")
                }
              />
            </li>
            <li className="user--researches--form--item">
              <div className="item--text semi__bold">
                <span>Лимит врачей</span>
              </div>
              <InputText
                value={
                  this.state.data.doctorsLimit !== "undefined"
                    ? this.state.data.doctorsLimit
                    : ""
                }
                onChange={this.doctorLimitChange}
                style={{ width: "80px" }}
              />
            </li>
            <li className="user--researches--form--item user--researches--form--item-horizontal">
              <div className="item--text semi__bold">
                <span>Врачи</span>

                <span className="item--text-link" onClick={this.onUploadExcel}>
                  Загрузить из Excel
                </span>
                <input
                  type="file"
                  style={{ display: "none" }}
                  ref={this.uploadExcel}
                  onChange={this.onUploadExelChange}
                  accept=".csv, .ods, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
              </div>
              {this.state.showSuccessImportAlert && (
                <Alert
                  className="user--researches--alert"
                  dismissible
                  variant="success"
                  onClose={() =>
                    this.setState({ showSuccessImportAlert: false })
                  }
                >
                  {`${
                    this.state.addedDoctorsCount === 1
                      ? "Прикреплен"
                      : "Прикреплено"
                  } ${this.state.addedDoctorsCount} ${getNoun(
                    this.state.addedDoctorsCount,
                    "пользователь",
                    "пользователя",
                    "пользователей"
                  )}`}
                </Alert>
              )}
              {this.state.importDoctorsError && (
                <div className="user--researches--error">
                  {this.state.importDoctorsError}
                </div>
              )}
              {this.state.limitsAlert && (
                <div style={{ color: "#019467", fontSize: 14 }}>
                  {this.state.limitsAlert}
                </div>
              )}
              {!!this.state.skippedDoctorsTableItems.length && (
                <Table
                  columns={importedDoctorsColumns}
                  data={this.state.skippedDoctorsTableItems}
                />
              )}
              <MultiSelectList
                key={this.state.doctors.length}
                data={this.state.doctors}
                selected={this.state.data.doctors}
                count={this.state.data.doctors.length}
                onChange={(data) => this.onUsersChange(data, "doctors")}
              />
            </li>
          </ul>
        </div>
        <div className="user--researches--card">
          <ul className="user--researches--form">
            <li className="user--researches--form--item-horizontal">
              <div className="item--text semi__bold">
                <span>Лимиты анкет по врачам</span>

                <span className="item--text-link" onClick={this.onUploadExcel}>
                  Загрузить из Excel
                </span>
                <input
                  type="file"
                  style={{ display: "none" }}
                  ref={this.uploadExcel}
                  onChange={this.onUploadExelChange}
                  accept=".csv, .ods, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
              </div>
              {this.state.limitsUpdatedAlert && (
                <div style={{ color: "#019467", fontSize: 14 }}>
                  {this.state.limitsUpdatedAlert}
                </div>
              )}
              <LimitTable
                limits={this.state.userLimits.filter(
                  (user) => user.formsLimit > 0
                )}
                onLimitChange={this.handleChangeLimit}
                onLimitDelete={this.handleDeleteLimit}
              />
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default UserResearches;
