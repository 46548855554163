import { type FC } from "react";
import { Button, Modal } from "react-bootstrap";
import store from "../../store/store";
import { ICity } from "./Table";

interface IRemoveCityModalProps {
  city: ICity;
  open: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}

export const RemoveCityModal: FC<IRemoveCityModalProps> = ({
  open,
  city,
  onClose,
  onSuccess,
}) => {
  const handleRemoveCity = () => {
    fetch(global.apiUrl + "/cities/remove", {
      method: "POST",
      body: JSON.stringify({
        id: city.id,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        store.dispatch({
          type: "PUSH_NOTIFICATION",
          payload: {
            type: "success",
            title: "",
            text: "Город удалён",
          },
        });
        onSuccess?.();
        onClose();
      })
      .catch((err) => console.log(err));
  };

  return (
    <Modal show={open} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Удаление города</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Вы действительно хотите удалить город {city.name}?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Отмена
        </Button>
        <Button variant="danger" onClick={handleRemoveCity}>
          Удалить
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
