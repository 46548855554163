import React, { useCallback, useState } from "react";
import { Alert, Button } from "react-bootstrap";
import arrowRight from "../../images/icons/arrow-right.svg";
import PropTypes from "prop-types";

export const ResetPassword = ({ handleBackToLogin }) => {
  const [isResolved, setIsResolved] = useState(false);
  const [isError, setIsError] = useState(false);
  const [email, setEmail] = useState("");

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    setIsError(false);
    setIsResolved(false);
    if (e.target[0].value) {
      fetch(global.apiUrl + "/user/restore", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(e.target[0].value),
      })
        .then((res) => res.text())
        .then((res) => {
          if (res === "not found") {
            return setIsError(true);
          }
          setIsResolved(true);
        });
    }
  }, []);

  const handleChangeEmail = useCallback((e) => {
    const value = e.target.value.split(" ").join("");
    setEmail(value);
  }, []);

  const onKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  return (
    <div className="login-wrapper">
      <h1>Восстановление пароля</h1>
      <form onSubmit={handleSubmit}>
        <label>
          <p>Email</p>
          <input
            value={email}
            onChange={handleChangeEmail}
            className="form-input-reset"
            type="email"
            onKeyDown={onKeyDown}
          />
        </label>
        <div className={"reset-password-button"}>
          <button className="button" type="submit">
            Отправить
          </button>
        </div>
        {isResolved && (
          <Alert variant={"success"}>
            На указанный email было направлено письмо с инструкциями
          </Alert>
        )}
        {isError && (
          <Alert variant={"danger"}>Такого пользователя не существует</Alert>
        )}
        <Button
          className={"reset-password-to-login"}
          variant="light"
          onClick={handleBackToLogin}
        >
          На страницу авторизации
          <img className={"to-login-ico"} src={arrowRight} alt="" />
        </Button>
      </form>
    </div>
  );
};

ResetPassword.propTypes = {
  handleBackToLogin: PropTypes.func,
};
