import React from "react";
import "./App.scss";
//Use Router
import {Alert} from 'react-bootstrap';
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Route, Routes } from "react-router-dom";
//Use Bootstrap styles
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "./views/login/login.jsx";
import Restore, { RestoreValidate } from "./views/login/restore.jsx";
//Import components
import ToastComponent from "./components/toastComponent";
import { withRouter } from "./components/withRouter";
import Header from "./parts/header";
import AsideMenu from "./parts/menu";
import routes from "./routes";
//Import Fonts
import "./formbuilder.css";
import store from "./store/store";
//Import project styles
import { connect } from "react-redux";
import chat from "./chat";
import { getCurrentUser } from "./components/userComponent/services/userInfo";
import "./styles/style.css";
<script
  src="https://kit.fontawesome.com/e473e08353.js"
  crossorigin="anonymous"
></script>;

if (sessionStorage.getItem("token")) {
  localStorage.setItem("token", sessionStorage.getItem("token"));
}
if (localStorage.getItem("token")) {
  sessionStorage.setItem("token", localStorage.getItem("token"));
}

function getToken() {
  const tokenString = sessionStorage.getItem("token");
  const userToken = JSON.parse(tokenString);
  return userToken?.token;
}

function setToken(userToken) {
  sessionStorage.setItem("token", JSON.stringify(userToken));
  window.location.reload();
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      initLoading: true,
      user: null,
      breadcrumbs: [
        {
          title: "Главная",
          url: "/",
        },
      ],
      location: this.props.location,
    };
  }

  componentDidMount() {
    this.routerUpdate(this.state.location);

    chat();

    getCurrentUser()
      .then((user) => {
        if (user?.id) {
          this.setState({ user });
        }
      })
      .finally(() => this.setState({ initLoading: false }));
  }

  componentDidUpdate() {
    if (this.props.location !== this.state.location) {
      this.routerUpdate(this.props.location);
    }
  }

  routerUpdate = (location) => {
    const { pathname } = location;
    const paths = pathname.split("/");

    const newBreadcrumbs = [];
    let lastPath = "";

    paths.forEach((path, i) => {
      if (path === "") {
        if (i === 0) {
          newBreadcrumbs.push({
            title: "Главная",
            url: "/",
          });
          lastPath += "/";
        }
      } else {
        const currentPath = lastPath + path + "/";
        let currentTitle = "";
        routes.forEach((route) => {
          if (route.url === currentPath || route.url === currentPath + ":id")
            currentTitle = route.title;
        });

        if (currentTitle) {
          newBreadcrumbs.push({
            title: currentTitle,
            url: currentPath,
          });
        }

        lastPath = currentPath;
      }
    });
    this.setState((state) => {
      state.breadcrumbs = newBreadcrumbs;
      state.location = location;
      return { ...state };
    });
  };

  render = () => {
    const token = getToken();
    if (window.location.href.split("hash=")[1]) {
      return <RestoreValidate />;
    }
    if (
      ((!this.state.user && !this.state.initLoading) || !token) &&
      window.location.href !== "/restore"
    ) {
      return <Login setToken={setToken} />;
    }
    if (window.location.href === "/restore") {
      return <Restore />;
    }

    return (
      <Container className="page p-0" fluid>
        <Row className="m-0">
          <Col md={2} className="p-0" id="menu-container">
            <AsideMenu className="aside--menu " />
          </Col>
          <Col md={10} className="p-0">
            <div className="page--content">
              <Header
                breadcrumbs={this.state.breadcrumbs}
                user={this.state.user}
              />
              <div className="page--routs">
                {/*<Alert variant={'warning'}>
                        ⚠️ 15.02.24 с 13:00 до 17:00 часов по МСК запланировано техническое обслуживание системы, портал будет недоступен
    </Alert>*/}
                <Routes>
                  {routes.map((route, i) => (
                    <Route path={route.url} element={route.page} key={i} />
                  ))}
                </Routes>
              </div>
            </div>
          </Col>
        </Row>
        <ToastComponent
          notifications={this.props.notifications}
          onClose={(id) =>
            store.dispatch({ type: "REMOVE_NOTIFICATION", payload: { id } })
          }
        />
      </Container>
    );
  };
}

const mapStateToProps = (state) => {
  return { notifications: state.notifications };
};

export default connect(mapStateToProps)(withRouter(App));
