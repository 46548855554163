import { Button, Container, Row, Col, Table, Tabs, Tab, Dropdown, Form, InputGroup, Alert } from "react-bootstrap";
import React, { Component, useState } from "react";

import { RD, ActionsTable, CountPicker, PaginationBasic, CustomersTable } from "../../components/ddTest.js";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    &#x25bc;
  </a>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value, setValue] = useState('');

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Введите для поиска"
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.join("").toLowerCase().includes(value.toLowerCase()),
          )}
        </ul>
      </div>
    );
  },
);
export class RequestorAdd extends React.Component {
  constructor(props) {
    super();
    this.state = { data: {}, users: [] }
  }
  handleChange(event) {
    this.setState((state) => {
      state.deleted = state.deleted ? 0 : 1;
      return (state)
    })
  }
  handleSubmit(event) {
    event.preventDefault();
    var data = this.state.data;
    data.name = event.target[0].value;
    data.delegate = JSON.parse(sessionStorage.getItem('token')).insecureid;
    data.agent = this.state.id_user;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: 'follow'
    };
    fetch(global.apiUrl + "/requestors/add", requestOptions)
      .then(response => response.json())
      .then(result => {
        this.setState((state) => {
          state.msg = 1;
          return (state)

        })
        return this.state;
      })
      .catch(error => {
        console.log(error);


      });
  }
  componentDidMount() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({ "data": window.location.href.split("?")[1] ? window.location.href.split("?")[1].split("=")[1] : 1 }),
      redirect: 'follow'
    };
    fetch(global.apiUrl + "/data/users/users", requestOptions)
      .then(response => response.json())
      .then(result => {
        this.setState((state) => {
          state.users = result;
          return state;
        })
        return this.state;
      })
      .catch(error => console.log(error));

  }
  selectTest(x) {
    this.setState((state) => {
      state.renderer = x.id + " " + x.surname + " " + x.name;
      state.id_user = x.id;
      return state;
    })
  }
  render() {
    const data = this.state;
    const users = this.user;
    return (
      <div className="container-fluid" id="main-page-block">
        <div id="page-content">
          <h2>
            Создание заказчика

          </h2>
          <Form onSubmit={this.handleSubmit.bind(this)}>
            <div className="new--researches">
              <div className="new--researches--card">
                <h4 className="new--researches--subtitle bold">Данные заказчика</h4>
                <ul className="new--researches--form">
                  <li className="new--researches--form--item">
                    <div className="item--text semi__bold">
                      <span>Название заказчика</span>
                    </div>
                    <Form.Control type="name" placeholder="Название заказчика" />
                  </li>
                  <li className="new--researches--form--item">
                    <div className="item--text semi__bold">
                      <span>Представитель заказчика</span>
                    </div>
                    <Dropdown>
                      {this.state?.selected}
                      <Dropdown.Toggle id="dropdown-custom-components">
                        {(this.state.renderer ? this.state.renderer : (this.state.data.agent ? this.state.data.agent : "Выберите пользователя"))}

                      </Dropdown.Toggle>
                      <Dropdown.Menu as={CustomMenu} onSelect={this.selectTest}>
                        {this.state.users.map((x, i) =>
                          <Dropdown.Item key={x.id} onClick={() => this.selectTest(x)}>{x.id} {x.surname} {x.name} </Dropdown.Item>
                        )}

                      </Dropdown.Menu>
                    </Dropdown>
                  </li>

                  <Button variant="primary" type="submit">
                    Добавить заказчика
                  </Button>

                </ul>
              </div>
            </div>
          </Form>
        </div>
        {this.state.msg ? <Alert key="success" variant="success">Заказчик создан. <a href='/customers/'>Вернуться назад</a></Alert> : null}
      </div>
    );
  }
}
