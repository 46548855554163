import { useEffect, useRef, useState, type FC } from "react";
import { SearchIcon } from "../../tables/assets/icons";
import { SelectPageCount } from "../../tables/formsTable/components/pagination/components";
import ReactPaginate from "react-paginate";
import "./styles.scss";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { Button } from "react-bootstrap";
import { AddCityModal } from "./AddCityModal";
import IMAGES from "../../images/iconSet";
import { RemoveCityModal } from "./RemoveCityModal";
import store from "../../store/store";
import axios from "axios";

interface ITableProps {
  updateCount?: (count: number) => void;
}

export interface ICity {
  id: number;
  name: string;
  total_records: number;
}

const tableStorageKey = "CITIES_TABLE_FILTERS";

export const Table: FC<ITableProps> = ({ updateCount }) => {
  const [cities, setCities] = useState<ICity[]>([]);
  const ref = useRef(null);
  const [total, setTotal] = useState(0);
  const [filters, setFilters] = useLocalStorage(tableStorageKey, {
    limit: 30,
    offset: 0,
    query: "",
  });
  const [selected, setSelected] = useState<ICity | null>(null);

  const [openModal, setOpenModal] = useState(false);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);

  const handleRemoveClick = (city: ICity) => {
    setSelected(city);
    setOpenRemoveModal(true);
  };

  const handleSelectLimit = (limit: number) => {
    setFilters((prev) => ({ ...prev, limit, offset: 0 }));
  };

  const handlePageClick = (event: any) => {
    setFilters((prev) => ({ ...prev, offset: event.selected * prev.limit }));
  };

  const getCitites = () => {
    fetch(global.apiUrl + "/cities/search", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        limit: filters.limit,
        offset: filters.offset,
        query: filters.query,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        setCities(response);
        const total = response?.[0]?.total_records ?? 0;
        updateCount?.(total);
        setTotal(total);
      })
      .catch((err) => console.log(err));
  };

  const uploadCitiesList = (e) => {
    const files = [...e.target.files];
    let formData = new FormData();
    formData.append("file", files[0]);
    axios
      .post(global.apiUrl + "/cities/xlsx", formData)
      .then((data) => {
        store.dispatch({
          type: "PUSH_NOTIFICATION",
          payload: {
            type: "success",
            title: "",
            text: "Город добавлены",
          },
        });
        getCitites();
      })
      .catch((err) => {
        store.dispatch({
          type: "PUSH_NOTIFICATION",
          payload: {
            type: "error",
            title: "",
            text: err.response.data,
          },
        });
      });
  };

  useEffect(() => {
    getCitites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const pageCount = Math.ceil(total / filters.limit);

  return (
    <>
      <div className={"wrapper-table"}>
        <div className="filters">
          <div className="filters__item">
            <div className={"input-field-search"}>
              <SearchIcon width={12} height={12} />
              <input
                value={filters.query}
                onChange={(e) =>
                  setFilters((prev) => ({ ...prev, query: e.target.value }))
                }
                type="text"
                placeholder={"Поиск..."}
                className={"input-field-search__input"}
              />
            </div>
          </div>
          <div className="filters__item">
            <Button onClick={() => setOpenModal(true)}>Добавить</Button>
          </div>
          <div className="filters__item">
            <Button
              onClick={() => {
                if (ref?.current?.value) {
                  ref.current.value = "";
                }
                ref?.current?.click();
              }}
            >
              Загрузить из Excel
            </Button>
            <input
              type="file"
              style={{ display: "none" }}
              ref={ref}
              onChange={uploadCitiesList}
              accept=".csv, .ods, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </div>
        </div>

        <div className={"table"}>
          <table style={{ width: "100%" }}>
            <thead className={"table-row table-row--header"}>
              <tr className={"table-row table-row--header"}>
                <th className={"table-row__item"}>
                  <span className={"table-head"}>Город</span>
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {cities &&
                cities.map((item, i) => (
                  <tr className={"table-row table-row--bg"} key={item.id}>
                    <td className={"table-row__item"}>
                      <div className={"table-row__wrap-text"}>
                        <span className={"table-row__text"}>{item.name}</span>
                      </div>
                    </td>
                    <td
                      className={"table-row__item table-row--action-text"}
                      align="right"
                      onClick={() => handleRemoveClick(item)}
                    >
                      Удалить
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className={"pagination-wrapper"}>
            <SelectPageCount
              handleSelect={handleSelectLimit}
              placeholder={String(filters.limit)}
            />
            <ReactPaginate
              breakLabel="..."
              nextLabel=""
              onPageChange={handlePageClick}
              pageRangeDisplayed={2}
              marginPagesDisplayed={1}
              pageCount={pageCount}
              forcePage={Math.round(filters.offset / filters.limit)}
              previousLabel=""
              className={"pagination"}
              previousLinkClassName={
                "pagination__button pagination__button--prev"
              }
              nextLinkClassName={"pagination__button pagination__button--next"}
              pageClassName={"pagination__page"}
              breakClassName={"pagination__break"}
              activeClassName={"pagination__page--active"}
            />
          </div>
        </div>
      </div>
      <AddCityModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        onSuccess={getCitites}
      />
      {selected && (
        <RemoveCityModal
          city={selected}
          open={openRemoveModal}
          onClose={() => setOpenRemoveModal(false)}
          onSuccess={() => {
            setFilters((prev) => ({ ...prev, query: "" }));
          }}
        />
      )}
    </>
  );
};
