import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Alert } from "react-bootstrap";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import { withRouter } from "../withRouter";
import store from "../../store/store";
import { Typeahead } from "react-bootstrap-typeahead";

import Col from "react-bootstrap/Col";

class UserEditNew extends React.Component {
  constructor({ props }) {
    super(props);
    this.state = {
      city: [],
      cities: [],
      data: {},
      msg: "",
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        ...this.state.data,
        city: this.state.city[0]?.id,
      }),
      redirect: "follow",
    };

    fetch(global.apiUrl + "/user/update", requestOptions).then(
      (response) => {
        store.dispatch({
          type: "PUSH_NOTIFICATION",
          payload: {
            type: "success",
            title: "",
            text: "Данные пользователи изменены",
          },
        });
        return response.json();
      }
    );
  };
  componentDidMount() {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let search = this.props.location.search.substring(1);
    let params = {};

    search = search.split("&");
    search.forEach((elem, i) => {
      const tmp = elem.split("=");
      params[tmp[0]] = tmp[1];
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({ id: params.id }),
      redirect: "follow",
    };

    fetch(global.apiUrl + "/user/edit", requestOptions)
      .then((response) => {
        if (response.ok) {
          delete this.state.msg;
          return response.json();
        }
        this.setState((state) => {
          state.msg = 1;
          return state;
        });
        throw new Error("User exists");
      })
      .then((result) => {
        this.setState(
          (state) => {
            result.userPassword = "";
            state.data = result;
            return state;
          },
          () => {
            // const tim = setTimeout(()=>{
            //     const {state} = this;
            //     state.userPassword = "";
            //     this.setState(state, ()=>{
            //         clearTimeout(tim);
            //     });
            // }, 300);
          }
        );
      })
      .catch((error) => {
        console.log("error handler was called");
        console.log(error);
      });
  }

  componentDidUpdate() {
    if (this.state.data?.id && this.state.cities.length === 0) {
      fetch(global.apiUrl + "/data/cities", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(sessionStorage.getItem("token")),
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.message === "ok") {
            this.setState({
              cities: data.response,
              city:
                data.response.filter(
                  (c) => c.id === Number(this.state.data?.city)
                ) ?? [],
            });
          }
        });
    }
  }

  onCityChange = (selectedCity) => {
    this.setState({ city: selectedCity });
  };

  chnageInputValue = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.getAttribute("dataname")]: e.target.value,
      },
    });
  };

  getRole = (role) => {
    this.setState({
      data: {
        ...this.state.data,
        role: role,
      },
    });
  };

  render() {
    return (
      <Form
        onSubmit={this.handleSubmit}
        className="advanced_fields col-md-6"
        autoComplete="off"
      >
        {/* ТУТ КОСТЫЛЬ ЧТОБЫ ОБОЙТИ АВТОЗАПОЛНЕНИЕ */}
        <input
          type="login"
          style={{
            opacity: 0,
            maxHeight: "0px",
            transform: "translateX(-200vw)",
            position: "absolute",
          }}
        />
        <input
          type="password"
          style={{
            opacity: 0,
            maxHeight: "0px",
            transform: "translateX(-200vw)",
            position: "absolute",
          }}
        />

        <Form.Group className="mb-3 row" controlId="formBasicSurname">
          <Form.Label className="col-sm-3 col-form-label">
            Фамилия<span className="require">*</span>
          </Form.Label>
          <Col sm="9">
            <Form.Control
              autoComplete="off"
              onChange={this.chnageInputValue}
              dataname="surname"
              type="text"
              required
              placeholder="Фамилия"
              defaultValue={
                this.state.data.surname != "null" ? this.state.data.surname : ""
              }
            />
          </Col>
        </Form.Group>

        <Form.Group className="mb-3 row" controlId="formBasicName">
          <Form.Label className="col-sm-3 col-form-label">
            Имя<span className="require">*</span>
          </Form.Label>
          <Col sm="9">
            <Form.Control
              autoComplete="off"
              onChange={this.chnageInputValue}
              dataname="name"
              type="text"
              required
              placeholder="Имя"
              defaultValue={
                this.state.data.name != "null" ? this.state.data.name : ""
              }
            />
          </Col>
        </Form.Group>

        <Form.Group className="mb-3 row" controlId="formBasicPatronymic">
          <Form.Label className="col-sm-3 col-form-label">Отчество</Form.Label>
          <Col sm="9">
            <Form.Control
              autoComplete="off"
              onChange={this.chnageInputValue}
              dataname="patronymic"
              type="text"
              placeholder="Отчество"
              defaultValue={
                this.state.data.patronymic != "null"
                  ? this.state.data.patronymic
                  : ""
              }
            />
          </Col>
        </Form.Group>
        <Form.Group className="mb-3 row" controlId="formBasicEmail">
          <Form.Label className="col-sm-3 col-form-label">
            Email<span className="require">*</span>
          </Form.Label>
          <Col sm="9">
            <Form.Control
              autoComplete="off"
              onChange={this.chnageInputValue}
              dataname="email"
              required
              placeholder="name@example.com"
              type="email"
              aria-label="Input group example"
              aria-describedby="btnGroupAddon2"
              defaultValue={this.state.data.email}
            />
          </Col>
        </Form.Group>

        <Form.Group className="mb-3 row" controlId="formBasicCity">
          <Form.Label className="col-sm-3 col-form-label">Город</Form.Label>
          <Col sm="9">
            <Typeahead
              id="city"
              labelKey="value"
              onChange={this.onCityChange}
              options={this.state.cities}
              placeholder="Выбрать город"
              selected={this.state.city}
              paginationText="Показать ещё..."
              emptyLabel="Совпадений не найдено"
            />
          </Col>
        </Form.Group>

        {/* <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Пароль пользователя</Form.Label>
                    <Form.Control
                        onChange={chnageInputValue}
                        dataname="userPassword"
                        type="password"
                        placeholder="Пароль пользователя"
                        value={this.state.userPassword}
                        style={{ background: "#ffffff", border: "1px solid #eee" }}
                    />
                </Form.Group> */}
        <Form.Group className="mb-3" controlId="formBasicRole">
          <Form.Label>
            Роль пользователя <span className="require">*</span>
          </Form.Label>
          <ButtonToolbar
            aria-label="Toolbar with button groups"
            className={"btncontrol"}
          >
            <ButtonGroup className="me-2" aria-label="First group">
              <Button
                onClick={() => this.getRole(0)}
                variant={this.state.data.role === 0 ? "success" : "light"}
              >
                Пользователь
              </Button>
              <Button
                onClick={() => this.getRole(1)}
                variant={this.state.data.role === 1 ? "success" : "light"}
              >
                Врач
              </Button>
              <Button
                onClick={() => this.getRole(2)}
                variant={this.state.data.role === 2 ? "success" : "light"}
              >
                Модератор
              </Button>
              <Button
                onClick={() => this.getRole(3)}
                variant={this.state.data.role === 3 ? "success" : "light"}
              >
                Монитор
              </Button>
              <Button
                onClick={() => this.getRole(4)}
                variant={this.state.data.role === 4 ? "success" : "light"}
              >
                Администратор
              </Button>
              <Button
                onClick={() => this.getRole(5)}
                variant={this.state.data.role === 5 ? "success" : "light"}
              >
                Суперадминистратор
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Form.Group>
        {this.state.data.role === 1 && (
          <div className="advanced_fields">

            <Form.Group className="mb-3 row" controlId="formBasicSpec">
              <Form.Label className="col-sm-3 col-form-label">
                Специализация врача
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  autoComplete="off"
                  onChange={this.chnageInputValue}
                  dataname="spec"
                  required
                  type="text"
                  placeholder="Специализация врача"
                  defaultValue={
                    this.state.data.spec === "null" ? "" : this.state.data.spec
                  }
                />
              </Col>
            </Form.Group>

            <Form.Group className="mb-3 row" controlId="formBasicOrg">
              <Form.Label className="col-sm-3 col-form-label">
                Организация
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  autoComplete="off"
                  onChange={this.chnageInputValue}
                  dataname="organization"
                  type="text"
                  placeholder="Организация"
                  defaultValue={
                    this.state.data.organization === "null"
                      ? ""
                      : this.state.data.organization
                  }
                />
              </Col>
            </Form.Group>

            <Form.Group className="mb-3 row" controlId="formBasicAdress">
              <Form.Label className="col-sm-3 col-form-label">
                Адрес организации
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  autoComplete="off"
                  onChange={this.chnageInputValue}
                  dataname="addres"
                  type="text"
                  placeholder="Адрес организации"
                  defaultValue={
                    this.state.data.addres === "null"
                      ? ""
                      : this.state.data.addres
                  }
                />
              </Col>
            </Form.Group>
          </div>
        )}
        <Form.Group className="mb-3 row" controlId="formBasicPhone">
          <Form.Label className="col-sm-3 col-form-label">
            Номер телефона
          </Form.Label>
          <Col sm="9">
            <Form.Control
              autoComplete="off"
              onChange={this.chnageInputValue}
              dataname="phone"
              type="number"
              placeholder="Номер телефона"
              defaultValue={this.state.data.phone}
            />
          </Col>
        </Form.Group>

        <Button variant="primary" type="submit">
          Изменить
        </Button>
        {this.state.msg && (
          <Alert variant="danger" style={{ marginTop: "20px" }}>
            <Alert.Heading>Ошибка!</Alert.Heading>
            <p>Email должен быть уникальным!</p>
          </Alert>
        )}
      </Form>
    );
  }
}

export default withRouter(UserEditNew);
