import "./index.scss";

import React from "react";

import axios from "axios";
import { Alert } from "react-bootstrap";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
import IMAGES from "../../../images/iconSet";
import Dates from "../../inputs/dates";
import InputFile from "../../inputs/file";
import MultiSelectList from "../../inputs/multiSelectList";
import Select from "../../inputs/select";
import InputText from "../../inputs/text";
import _ from "lodash";
import InputTextarea from "../../inputs/textarea";
import { RequireIcon } from "../../../shared/RequireIcon";
import store from "../../../store/store";

export class NewResearches extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      regions: [],
      requestors: [],
      lastDeadlineError: false,
    };

    this.debouncedOnChange = _.debounce(this.onChange, 5000);

    if (props.data) {
      this.state.data = {
        name: props.data.name ? props.data.name : "",
        dateFrom: props.data.dateFrom ? props.data.dateFrom : "",
        anketsLimit: props.data.anketsLimit ? props.data.anketsLimit : "",
        dateTo: props.data.dateTo ? props.data.dateTo : "",
        description: props.data.description ? props.data.description : "",
        foreignId: props.data.foreignId ? props.data.foreignId : "",
        object: props.data.object ? props.data.object : "",
        requestor: props.data.requestor ? props.data.requestor : "",
        regions: props.data.regions ? props.data.regions : [],
        lastDeadline: props.data.lastDeadline ? props.data.lastDeadline : "",
        notifyMail: props.data.notifyMail ? props.data.notifyMail : [],
      };
    }
  }

  componentDidMount = () => {
    fetch(global.apiUrl + "/data/cities", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: JSON.parse(sessionStorage.getItem("token")),
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.message === "ok") {
          this.setState({ regions: response.response });
        }
      })
      .catch((err) => console.log(err));

    fetch(global.apiUrl + "/data/requestors", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: JSON.parse(sessionStorage.getItem("token")),
      }),
    })
      .then((response) => {
        try {
          response.text().then((text) => {
            const requestors = JSON.parse(text);
            const requestorsArray = {};
            requestors.forEach((req, i) => {
              requestorsArray[req.id] = req.name;
            });
            this.state.requestors = requestorsArray;

            this.setState(this.state);
          });
        } catch {
          console.error("Ошибка при прочтении данных");
        }
      })
      .catch((err) => console.log(err));
  };

  onChange = () => {
    this.props.onChange(this.state.data);
  };

  onChangeText = (e) => {
    const { state } = this;

    state.data[e.target.name] = e.target.value;

    this.setState(state, this.debouncedOnChange);
  };

  onRegionsChange = (data) => {
    this.state.data.regions = data;
    this.setState(this.state, this.debouncedOnChange);
  };

  onUpdate = () => {
    this.props.onUpdate(this.state.data);
    store.dispatch({
      type: "PUSH_NOTIFICATION",
      payload: {
        type: "success",
        title: "Обновление",
        text: "Данные успешно обновлены",
      },
    });
  };

  onReqestorChange = (id) => {
    this.state.data.requestor = id;
    this.setState(this.state, this.debouncedOnChange);
  };

  uploadFile = (file, onUploadProgress) => {
    let formData = new FormData();

    formData.append("researchAttachment", file);
    formData.append("id", this.props.id);

    return axios
      .post(global.apiUrl + "/research/s3upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
      })
      .then(({ data }) => data);
  };

  removeFile = (source) => {
    return axios
      .post(global.apiUrl + "/research/unlink", { source })
      .then(({ data }) => data);
  };

  onDateChange = (date, type) => {
    const { state } = this;

    if (type === "from") {
      if (date > state.data.dateTo && state.data.dateTo) {
      } else {
        state.data.dateFrom = date;
      }
    }
    if (type === "to") {
      if (date < state.data.dateFrom && state.data.dateFrom) {
      } else {
        state.data.dateTo = date;
      }

      if (
        new Date(date).getTime() < new Date(state.data.lastDeadline).getTime()
      ) {
        return this.setState({ lastDeadlineError: true });
      }
    }
    state.lastDeadlineError = false;
    this.setState(state, this.onChange);
  };

  onLastDeadlineChange = (date) => {
    if (new Date(date).getTime() > new Date(this.state.data.dateTo).getTime()) {
      return this.setState({ lastDeadlineError: true });
    } else {
      this.setState((prev) => {
        return {
          ...prev,
          lastDeadlineError: false,
          data: { ...prev.data, lastDeadline: date },
        };
      }, this.onChange);
    }
  };

  onNotifyMailChange = (emails) => {
    this.setState(
      (prev) => ({ ...prev, data: { ...prev.data, notifyMail: emails } }),
      this.onChange
    );
  };

  render() {
    return (
      <>
        <div className="new--researches">
          <div className="new--researches--card">
            <ul className="new--researches--form">
              <li className="new--researches--form--item">
                <div className="item--text semi__bold">
                  <span>Название исследования <RequireIcon /></span>
                </div>
                <InputText
                  required
                  value={this.state.data.name}
                  onChange={this.onChangeText}
                  name="name"
                />
              </li>
              <li className="new--researches--form--item">
                <div className="item--text semi__bold">
                  <span>Срок исследования <RequireIcon /></span>
                </div>
                <div className="dates">
                  <Dates
                    className="mh-dates_input__filed"
                    defaultDate={
                      this.state.data.dateFrom !== "null" &&
                        this.state.data.dateFrom
                        ? new Date(
                          this.state.data.dateFrom
                        ).toLocaleDateString()
                        : ""
                    }
                    onDateChange={(date) => this.onDateChange(date, "from")}
                    style={{
                      background: "#ffffff",
                      border: "1px solid #E0E0E0",
                    }}
                  />

                  <Dates
                    className="mh-dates_input__filed"
                    defaultDate={
                      this.state.data.dateTo !== "null" &&
                        this.state.data.dateTo
                        ? new Date(this.state.data.dateTo).toLocaleDateString()
                        : ""
                    }
                    onDateChange={(date) => this.onDateChange(date, "to")}
                    style={{
                      background: "#ffffff",
                      border: "1px solid #E0E0E0",
                    }}
                  />
                </div>
              </li>
              <li className="new--researches--form--item">
                <div
                  className="item--text semi__bold"
                  style={{ maxWidth: "200px" }}
                >
                  <span>Крайний срок добавления анкет</span>
                </div>
                <div className="dates">
                  <Dates
                    className="mh-dates_input__filed"
                    defaultDate={
                      this.state.data.lastDeadline !== "null" &&
                        this.state.data.lastDeadline
                        ? new Date(
                          this.state.data.lastDeadline
                        ).toLocaleDateString()
                        : ""
                    }
                    onDateChange={this.onLastDeadlineChange}
                    style={{
                      background: "#ffffff",
                      border: "1px solid #E0E0E0",
                    }}
                  />
                  {this.state.lastDeadlineError && (
                    <Alert variant="danger">
                      Крайний срок добавления анкет должен быть меньше чем дата
                      окончания исследования
                    </Alert>
                  )}
                </div>
              </li>
              <li className="new--researches--form--item">
                <div className="item--text semi__bold">
                  <span>Лимит анкет на врача</span>
                </div>
                <InputText
                  type="number"
                  required
                  value={this.state.data.anketsLimit}
                  onChange={this.onChangeText}
                  name="anketsLimit"
                />
              </li>
              <li className="new--researches--form--item">
                <div className="item--text semi__bold">
                  <span>Описание</span>
                </div>
                <InputTextarea
                  value={this.state.data.description}
                  onChange={this.onChangeText}
                  name="description"
                />
              </li>
              <li className="new--researches--form--item">
                <div className="item--text semi__bold">
                  <span>Прикрепленные файлы</span>
                </div>
                <div style={{ width: "280px" }}>
                  <InputFile
                    text="Выбрать файлы"
                    multiple
                    files={this.props.data?.files ?? []}
                    uploadFile={this.uploadFile}
                    removeFile={this.removeFile}
                  />
                </div>
              </li>
            </ul>
          </div>
          <div className="new--researches--card">
            <ul className="new--researches--form">
              <li className="new--researches--form--item">
                <div className="item--text semi__bold">
                  <span>ID</span>
                </div>
                <InputText
                  type="text"
                  value={this.state.data.foreignId}
                  onChange={this.onChangeText}
                  name="foreignId"
                />
              </li>
              <li className="new--researches--form--item">
                <div className="item--text semi__bold">
                  <span>Объект</span>
                </div>
                <InputText
                  value={this.state.data.object}
                  onChange={this.onChangeText}
                  name="object"
                />
              </li>
              <li className="new--researches--form--item">
                <span className="item--text semi__bold">Заказчик</span>
                <Select
                  items={this.state.requestors}
                  onChange={this.onReqestorChange}
                  style={{ width: "100%" }}
                  value={this.state.data.requestor}
                  type="outline"
                />
              </li>
              <li className="new--researches--form--item">
                <span className="item--text semi__bold">Выбор региона</span>
                <MultiSelectList
                  key={this.state.regions.length}
                  data={this.state.regions}
                  selected={this.state.data.regions}
                  count={
                    this.state.data.regions ? this.state.data.regions.length : 0
                  }
                  onChange={this.onRegionsChange}
                />
              </li>
              <li className="new--researches--form--item">
                <span className="item--text semi__bold">
                  Почта для уведомлений
                </span>
                <ReactMultiEmail
                  emails={this.state.data.notifyMail}
                  onChange={this.onNotifyMailChange}
                  className="new--researches--form--multiinput"
                  getLabel={(email, index, removeEmail) => {
                    return (
                      <div
                        data-tag
                        key={index}
                        style={{ backgroundColor: "#EDF4FB" }}
                      >
                        <div data-tag-item>{email}</div>
                        <span
                          data-tag-handle
                          onClick={() => removeEmail(index)}
                        >
                          <img
                            src={IMAGES.constructor.removeSelectedItem}
                            alt=""
                          />
                        </span>
                      </div>
                    );
                  }}
                />
              </li>
            </ul>
          </div>
        </div>

        {this.props.id ? (
          <div className="new--researches--buttons">
            {this.props.status === "draft" ? (
              <button
                type="button"
                disabled={this.state.data.name.length == 0 ||
                  this.state.data.dateFrom.length == 0 ||
                  this.state.data.dateTo.length == 0}
                className="new--researches--button new--researches--publish"
                onClick={this.props.onSubmit}
              >
                Опубликовать
              </button>
            ) : (
              <button
                type="button"
                className="new--researches--button"
                onClick={this.onUpdate}
              >
                Обновить
              </button>
            )}
            {this.props.status !== "deleted" ? (
              <button
                type="button"
                className="new--researches--button new--researches--delete"
                onClick={this.props.onDelete}
              >
                <img src={IMAGES.constructor.trash} alt="" />
                {this.props.status === "draft" ? "Удалить" : "Архивировать"}
              </button>
            ) : (
              ""
            )}
            <div>
              {(this.state.data.name.length == 0 ||
                this.state.data.dateFrom.length == 0 ||
                this.state.data.dateTo.length == 0) && (
                  <Alert variant={'warning'}>
                    Заполните обязательные поля перед публикацией
                  </Alert>
                )}
            </div>
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default NewResearches;
