import { createAsyncThunk, createSlice, Draft } from "@reduxjs/toolkit";
import globalStore from "../../../store/store";
import api from "../../api";
import {
  FilterTypes,
  IQuestionnairesFilters,
  IQuestionnairesState,
  IResponseInit,
} from "./types";

interface IRequestProps {
  url: string;
  token: string;
}

export const lsTableFiltersKey = "TABLE_FILTERS";
export const lsTableUrlKey = "TABLE_URL";

const initialState: IQuestionnairesState = {
  currentUrl: localStorage.getItem(lsTableUrlKey)
    ? localStorage.getItem(lsTableUrlKey)
    : "",
  loader: false,
  selectedQuestionnaire: [],
  selectAll: false,
  token: "",
  initData: {
    UrlToFilter: "",
    fields: {},
    dropdownfilter: [],
    useSearch: false,
    usePaginator: false,
    actionButtons: [],
    useTabs: false,
    tabs: {},
    availableSorts: [],
    globalActionTable: {},
    fastFilters: [],
    content: [],
    allowFilterByDate: false,
  },
  content: [],
  selectors: [],
  filters: localStorage.getItem(lsTableFiltersKey)
    ? (JSON.parse(localStorage.getItem(lsTableFiltersKey))
        .filters as IQuestionnairesFilters[])
    : ([] as IQuestionnairesFilters[]),
};

export const getInitTable = createAsyncThunk(
  "table/getInitTable",
  async ({ url, token }: IRequestProps, { getState }) => {
    const { table } = getState() as any;
    const defaultUrl = table.currentUrl;

    if (localStorage.getItem(lsTableFiltersKey) && defaultUrl === url) {
      const filters = JSON.parse(
        localStorage.getItem(lsTableFiltersKey)
      ).filters;
      if (filters && filters.length > 0) {
        const cities = filters
          .filter((el) => el.type === FilterTypes.cities && "id" in el)
          .map((city) => ("id" in city ? city.id : undefined));
        const researches = filters
          .filter((el) => el.type === FilterTypes.researches && "id" in el)
          .map((res) => ("id" in res ? res.id : undefined));
        const data = {
          ...(cities.length > 0 && { city: cities }),
          ...(researches.length > 0 && { researches }),
          searchValue: filters.find(
            (el) => el.type === FilterTypes.searchValue && el.value
          )?.value,
        };
        const res = await api.post(url, { token, ...data });

        return {
          ...res.data,
          initData: {
            ...res.data.initData,
            content: res.data,
          },
        };
      } else {
        const res = await api.post(url, { token });
        return res.data;
      }
    } else {
      const res = await api.post(url, { token });
      return res.data;
    }
  }
);

export const sendFilterData = createAsyncThunk(
  "table/sendFilterData",
  async ({ data }: { data: any }, { getState }) => {
    try {
      const { table } = getState() as any;
      const { token, filters } = table;
      const defaultUrl = table.currentUrl;
      const { UrlToFilter } = table.initData;
      if (
        (data.cities && data.cities.length > 0) ||
        (data.search && data.search.length > 0) ||
        (data.researches && data.researches.length > 0) ||
        data.sorting
      ) {
        const dataToSend: any = {
          token: token,
        };
        if (
          data.cities
            ? data.cities
            : filters.filter((el) => el.type === FilterTypes.cities).length > 0
            ? filters
                .filter((el) => el.type === FilterTypes.cities)
                .map((el) => el.id)
            : false
        ) {
          dataToSend.city = data.cities
            ? data.cities
            : filters.filter((el) => el.type === FilterTypes.cities).length > 0
            ? filters
                .filter((el) => el.type === FilterTypes.cities)
                .map((el) => el.id)
            : [];
        }
        if (
          data.search
            ? data.search
            : filters.filter((el) => el.type === FilterTypes.searchValue)
                .length > 0
            ? filters.filter((el) => el.type === FilterTypes.searchValue)[0]
                .value
            : false
        ) {
          dataToSend.searchValue = data.search
            ? data.search
            : filters.filter((el) => el.type === FilterTypes.searchValue)
                .length > 0
            ? filters.filter((el) => el.type === FilterTypes.searchValue)[0]
                .value
            : undefined;
        }
        if (data.sorting) {
          dataToSend.sorting = data.sorting;
        }
        if (
          data.researches ||
          filters.filter((el) => el.type === FilterTypes.researches).length > 0
        ) {
          dataToSend.researches = data.researches
            ? data.researches
            : filters.filter((el) => el.type === FilterTypes.researches)
                .length > 0
            ? filters
                .filter((el) => el.type === FilterTypes.researches)
                .map((el) => el.id)
            : [];
        }
        return await api
          .post<IResponseInit>(UrlToFilter, dataToSend)
          .then((r) => {
            if (data.search) {
              return {
                data: r.data,
                filters: {
                  searchValue: data.search,
                  cities:
                    "cities" in data
                      ? data.cities
                      : filters.filter((el) => el.type === FilterTypes.cities),
                  researches:
                    "researches" in data
                      ? data.researches
                      : filters.filter(
                          (el) => el.type === FilterTypes.researches
                        ),
                },
              };
            } else if (data.cities) {
              return {
                data: r.data,
                filters: {
                  cities: data.cities,
                  researches:
                    "researches" in data
                      ? data.researches
                      : filters
                          .filter((el) => el.type === FilterTypes.researches)
                          .map((r) => r.id),
                  searchValue:
                    filters.filter((el) => el.type === FilterTypes.searchValue)
                      .length > 0
                      ? filters.filter(
                          (el) => el.type === FilterTypes.searchValue
                        )[0].value
                      : undefined,
                },
              };
            } else if (data.researches) {
              return {
                data: r.data,
                filters: {
                  researches: data.researches,
                  cities:
                    "cities" in data
                      ? data.cities
                      : filters
                          .filter((el) => el.type === FilterTypes.cities)
                          .map((c) => c.id),
                  searchValue:
                    filters.filter((el) => el.type === FilterTypes.searchValue)
                      .length > 0
                      ? filters.filter(
                          (el) => el.type === FilterTypes.searchValue
                        )[0].value
                      : undefined,
                },
              };
            } else {
              return {
                data: r.data,
              };
            }
          });
      } else {
        return await api
          .post<IResponseInit>(defaultUrl, {
            token: token,
          })
          .then((r) => {
            return {
              data: r.data,
              filters: {},
            };
          });
      }
    } catch (e: any) {}
  }
);

export const deleteItem = createAsyncThunk(
  "table/deleteItem",
  async ({ id, url }: { id: string; url: string }, { getState }) => {
    try {
      const { table } = getState() as any;
      const { token } = table;
      const res = await api.post(url, {
        id: id,
        token: token,
      });
      globalStore.dispatch({
        type: "PUSH_NOTIFICATION",
        payload: {
          type: "success",
          title: "",
          text: "Элемент удален",
        },
      });
      return res.data;
    } catch (e) {
      globalStore.dispatch({
        type: "PUSH_NOTIFICATION",
        payload: {
          type: "error",
          title: "",
          text: "Не удалось удалить элемент",
        },
      });
    }
  }
);

const questionnairesSlice = createSlice({
  name: "table",
  initialState,
  reducers: {
    setSelectAll: (
      state: Draft<{ selectAll }>,
      { payload }: { payload: boolean }
    ) => {
      state.selectAll = payload;
    },
    setSelectItem: (
      state: Draft<{ selectedQuestionnaire }>,
      { payload }: { payload: any }
    ) => {
      state.selectedQuestionnaire = payload;
    },
    setFilterTabs: (
      state: Draft<{ content }>,
      { payload }: { payload: any[] }
    ) => {
      state.content = payload;
    },
    setUrl: (state, { payload }) => {
      state.currentUrl = payload;
      localStorage.setItem(lsTableUrlKey, payload);
    },
    setToken: (state, { payload }: { payload: string }) => {
      state.token = payload;
    },
    removeFilter: (state, { payload }: { payload: IQuestionnairesFilters }) => {
      state.filters = state.filters.filter((el) => {
        if (el.type === payload.type) {
          if (el.type === FilterTypes.searchValue) {
            return false;
          } else {
            return el.id !== payload.id;
          }
        } else return true;
      });
      if (state.filters.length === 0) {
        localStorage.removeItem(lsTableFiltersKey);
      }
    },
    clearFilters: (state) => {
      state.filters = [];
      localStorage.removeItem(lsTableFiltersKey);
    },
    removeContent: (state) => {
      state.content = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInitTable.pending, (state: Draft<{ loader }>) => {
      state.loader = true;
    });
    builder.addCase(
      getInitTable.fulfilled,
      (
        state: Draft<{ loader; initData; content; filters }>,
        { payload }: { payload: IResponseInit }
      ) => {
        state.loader = false;
        state.initData = payload;
        state.content = payload?.content;
      }
    );
    builder.addCase(getInitTable.rejected, (state: Draft<{ loader }>) => {
      state.loader = false;
    });
    builder.addCase(
      deleteItem.fulfilled,
      (
        state: Draft<{ initData; content }>,
        { payload }: { payload: { deleted: number | string } }
      ) => {
        state.initData.content = state.initData.content.filter(
          (item) => item.id !== payload.deleted
        );
        state.content = state.content.filter(
          (item) => item.id !== payload.deleted
        );
      }
    );
    builder.addCase(sendFilterData.pending, (state: Draft<{ loader }>) => {
      state.loader = true;
    });
    builder.addCase(
      sendFilterData.fulfilled,
      (state: Draft<{ loader; initData; content; filters }>, { payload }) => {
        state.loader = false;
        state.initData.content = payload.data.content;
        state.content = payload.data.content;
        if ("filters" in payload && payload.filters) {
          let newFilters: IQuestionnairesFilters[] = [];
          Object.keys(payload.filters).forEach((key: FilterTypes) => {
            if (key !== FilterTypes.searchValue) {
              payload.filters[key].forEach((filter) => {
                let value = "";

                switch (key) {
                  case FilterTypes.cities:
                    value = state.initData.fields.city.cases.find(
                      (x) => x.case === filter
                    )?.showFilter;
                    break;
                  default:
                    value = state.initData.fields[key].cases.find(
                      (x) => x.case === filter
                    )?.showFilter;
                }

                newFilters.push({
                  type: key,
                  id: filter,
                  value: value,
                });
              });
            } else {
              if (payload.filters[key]) {
                newFilters.push({
                  type: FilterTypes.searchValue,
                  value: payload.filters[key],
                });
              }
            }
          });
          state.filters = newFilters;
        } else {
          state.filters = [];
        }
      }
    );
    builder.addCase(sendFilterData.rejected, (state: Draft<{ loader }>) => {
      state.loader = false;
    });
  },
});

export const {
  setSelectAll,
  setSelectItem,
  setFilterTabs,
  setToken,
  removeFilter,
  clearFilters,
  setUrl,
  removeContent,
} = questionnairesSlice.actions;

export default questionnairesSlice.reducer;
