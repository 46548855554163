import "./index.scss";

import React from "react";

import InputCheckbox from "../../inputs/checkbox";
import MultiSelectList from "../../inputs/multiSelectList";
import Checkbox from "../../inputs/checkbox";
import InputText from "../../inputs/text";
import { string } from "yup/lib/locale";

export class UserResearches extends React.Component {

   constructor(props) {
      super(props);

      this.state = {
         data: {},
         moderators: [],
         admins: [],
         monitors: [],
         doctors: [],
         useModeration: false
      };

      if (props.data) {
         this.state.data = {
            admins: props.data.admins ? props.data.admins : [],
            moderators: props.data.moderators ? props.data.moderators : [],
            monitors: props.data.monitors ? props.data.monitors : [],
            doctors: props.data.doctors ? props.data.doctors : [],
            doctorsLimit: props.data.doctorsLimit ? props.data.doctorsLimit : ""
         }

         if (props.data.moderators) {
            this.state.useModeration = props.data.moderators.length > 0 ? true : false;
         }
      }

      this.uploadExcel = React.createRef();
   }

   onChange = () => {
      this.props.onChange(this.state.data);
   }

   componentDidMount = () => {
      this.onChange();

      fetch(global.apiUrl + "/data/users", {
         method: "post",
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
         },
         body: JSON.stringify({
            token: JSON.parse(sessionStorage.getItem('token'))
         }),
      }).then(response => {
         try {
            response.text().then(text => {
               const users = JSON.parse(text);

               const moderators = [];
               const admins = [];
               const monitors = [];
               const doctors = [];

               users.forEach((user, i) => {
                  const tmpUser = {
                     id: user.id,
                     value: "(" + user.id + ") " + user.surname + " " + user.name + " " + user.patronymic
                  };
                  if (parseInt(user.role) === 2) moderators.push(tmpUser);
                  if (parseInt(user.role) === 4) admins.push(tmpUser);
                  if (parseInt(user.role) === 3) monitors.push(tmpUser);
                  if (parseInt(user.role) === 1) doctors.push(tmpUser);
               })

               const state = { ...this.state, moderators, monitors, admins, doctors };
               this.setState(state);
            });
         }
         catch {
            console.error("Ошибка при прочтении данных");
         }
      }).catch(err => console.log(err));
   }

   onUsersChange = (data, obj) => {
      this.state.data[obj] = data;
      this.setState(this.state, this.onChange);
   }

   onModerationChange = (e) => {
      this.state.useModeration = e.target.checked;
      if (e.target.checked === false) {
         this.state.data.moderators = [];
      }
      this.setState(this.state, this.onChange);
   }

   doctorLimitChange = (e) => {
      const string = [...e.target.value];
      let limit = "";
      string.forEach(char => {
         if ("0123456789".indexOf(char) > -1) {
            limit += char;
         }
      });
      this.state.data.doctorsLimit = limit;
      this.setState(this.state);
   }

   onUploadExcel = () => {
      this.uploadExcel.current.click();
   }

   render() {
      return (
         <div className="user--researches">
            <div className="user--researches--card">
               <ul className="user--researches--form">
                  <li className="user--researches--form--item">
                     <div className="item--text semi__bold">
                        <Checkbox value={this.state.useModeration} onChange={this.onModerationChange}>Анкеты проходят модерации перед принятием</Checkbox>
                     </div>
                  </li>
                  {
                     this.state.useModeration ? (
                        <li className="user--researches--form--item">
                           <div className="item--text semi__bold">
                              <span>Выбор модератора</span>
                           </div>
                           <MultiSelectList
                              key={this.state.moderators.length}
                              data={this.state.moderators}
                              selected={this.state.data.moderators}
                              count={this.state.data.moderators.length}
                              onChange={(data) => this.onUsersChange(data, "moderators")}
                           />
                        </li>
                     ) : ""
                  }
                  <li className="user--researches--form--item">
                     <div className="item--text semi__bold">
                        <span>Выбор администратора</span>
                     </div>
                     <MultiSelectList
                        key={this.state.admins.length}
                        data={this.state.admins}
                        selected={this.state.data.admins}
                        count={this.state.data.admins.length}
                        onChange={(data) => this.onUsersChange(data, "admins")}
                     />
                  </li>
                  <li className="user--researches--form--item">
                     <div className="item--text semi__bold">
                        <span>Выбор монитора</span>
                     </div>
                     <MultiSelectList
                        key={this.state.monitors.length}
                        data={this.state.monitors}
                        selected={this.state.data.monitors}
                        count={this.state.data.monitors.length}
                        onChange={(data) => this.onUsersChange(data, "monitors")}
                     />
                  </li>
               </ul>
            </div>
            <div className="user--researches--card">
               <ul className="user--researches--form">
                  <li className="user--researches--form--item">
                     <div className="item--text semi__bold">
                        <span>Лимит врачей</span>
                     </div>
                     <InputText
                        value={this.state.data.doctorsLimit}
                        onChange={this.doctorLimitChange}
                        style={{ width: "80px" }}
                     />
                  </li>
                  <li className="user--researches--form--item user--researches--form--item-horizontal">
                     <div className="item--text semi__bold">
                        <span>Врачи</span>

                        <span className="item--text-link" onClick={this.onUploadExcel}>Загрузить из Excel</span>
                        <input type="file" style={{ display: "none" }} ref={this.uploadExcel} accept=".xls,.xlsx" />
                     </div>
                     <MultiSelectList
                        key={this.state.doctors.length}
                        data={this.state.doctors}
                        selected={this.state.data.doctors}
                        count={this.state.data.doctors.length}
                        onChange={(data) => this.onUsersChange(data, "doctors")}
                     />
                  </li>
               </ul>
            </div>
         </div>
      );
   }
}

export default UserResearches;
