import { useState, type FC } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import store from "../../store/store";

interface IAddCityModalProps {
  open: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}

export const AddCityModal: FC<IAddCityModalProps> = ({ open, onClose, onSuccess }) => {
  const [name, setName] = useState("");

  const handleAddCity = () => {
    fetch(global.apiUrl + "/cities/add", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: name,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        store.dispatch({
          type: "PUSH_NOTIFICATION",
          payload: {
            type: "success",
            title: "",
            text: "Город добавлен",
          },
        });
        onSuccess?.();
        onClose();
      })
      .catch((err) => console.log(err));
  };

  return (
    <Modal show={open} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Добавление города</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Название"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Отмена
        </Button>
        <Button variant="success" onClick={handleAddCity}>
          Добавить
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
