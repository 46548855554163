import { useEffect, useState, type FC } from "react";
import { Button, Form } from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Dates from "../../components/inputs/dates";
import IMAGES from "../../images/iconSet";
import moment from "moment";
import form from "../../reactfb/rfb/src/form";

type Doctor = {
  id: number;
  email: string;
  name: string;
  surname: string;
  patronymic: string;
};

export const Reports: FC = () => {
  const [doctors, setDoctors] = useState<Doctor[]>([]);
  const [selectedDoctors, setSelectedDoctors] = useState<Doctor[]>([]);
  const [period, setPeriod] = useState("999");
  const [researches, setResearches] = useState<
    { originalId: string; id: string; name: string }[]
  >([]);
  const [selectedResearch, setSelectedResearch] = useState<string>("");
  const [selectedResearchName, setSelectedResearchName] = useState<string>("");
  // const [withPeriod, setWithPeriod] = useState(false);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState(new Date());
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    fetch(global.apiUrl + "/research/list/", {
      method: "POST",
      body: JSON.stringify({ token: sessionStorage.getItem("token") }),
    })
      .then((res) => res.json())
      .then((data) => setResearches(data.content));
  }, []);

  const onSearch = (query: string) => {
    setIsSearchLoading(true);
    fetch(global.apiUrl + "/research/getReportByInterval/suggestUser", {
      method: "POST",
      body: JSON.stringify({
        searchString: query,
      }),
    })
      .then((res) => res.json())
      .then((data: Doctor[]) => setDoctors(data))
      .finally(() => setIsSearchLoading(false));
  };

  const onSubmit = () => {
    setIsSubmitting(true);
    fetch(global.apiUrl + "/research/getReportByInterval", {
      method: "POST",
      headers: {
        "Content-type": "application/octet-stream",
      },
      body: JSON.stringify({
        user_id: selectedDoctors.map((el) => el.id),
        research_id: selectedResearch,
        dateFrom: dateFrom
          ? moment(dateFrom).format("YYYY-MM-DD HH:mm:ss")
          : undefined,
        dateTo: dateTo
          ? moment(dateTo).format("YYYY-MM-DD HH:mm:ss")
          : undefined,
        scope: period,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          return res.blob();
        }
        return Promise.reject();
      })
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", `Отчет ${selectedResearch}${(dateFrom && dateTo) ? ` ${moment(dateFrom).format("YYYY.MM.DD")} - ${moment(dateTo).format("YYYY.MM.DD")}` : ``}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <div
      className="p-4 card fs-4 d-flex flex-column gap-3"
      style={{ width: 470 }}
    >
      Конструктор отчетов
      <Form.Group>
        <Form.Label className="fs-6">Вид отчета</Form.Label>
        <Form.Select>
          <option value="full">Отчет по количеству внесенных визитов</option>
        </Form.Select>
      </Form.Group>
      <Form.Group>
        <Form.Label className="fs-6">Исследование</Form.Label>
        <Form.Select
          value={selectedResearch}
          onChange={(e) => {
            setSelectedResearch(e.target.value);
          }}
        >
          <option value="" disabled hidden>
            Выбрать
          </option>
          {researches.map((res, index) => (
            <option key={index} title={res.name} value={res.originalId}>
              {res.name}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <Form.Group>
        <Form.Label className="fs-6">Врачи</Form.Label>
        <AsyncTypeahead
          id="doctors-search"
          multiple
          selected={selectedDoctors}
          onChange={(doctors) => setSelectedDoctors(doctors as Doctor[])}
          isLoading={isSearchLoading}
          options={doctors}
          labelKey={(option: Doctor) =>
            `${option.surname} ${option.name} ${option.patronymic}`
          }
          paginationText="Показать ещё..."
          emptyLabel="Совпадений не найдено"
          placeholder="Поиск..."
          promptText="Поиск..."
          searchText="Поиск..."
          onSearch={onSearch}
        />
      </Form.Group>
      {/* <Form.Check
        label="Указать период"
        className="fs-6"
        checked={withPeriod}
        onChange={(e) => setWithPeriod(e.target.checked)}
      /> */}
      <Form.Label className="fs-6">Период</Form.Label>
      <Form.Group>
        <div className="d-flex" style={{ fontSize: 16 }}>
          <Dates
            placeholder="Начало"
            onDateChange={(date) => setDateFrom(date)}
          />
          <Dates
            wrapperClassName="ms-2"
            placeholder="Конец"
            defaultDate={new Date().toLocaleDateString()}
            onDateChange={(date) => setDateTo(date)}
          />
        </div>
        {/* {dateError && (
          <Alert className="fs-6 mt-2 mb-0" variant="danger">
            Некорректные значения периода
          </Alert>
        )} */}
      </Form.Group>
      <Form.Group>
        <Form.Label className="fs-6">Детализация</Form.Label>
        <Form.Select value={period} onChange={(e) => setPeriod(e.target.value)}>
          <option value="1">По 1 дню</option>
          <option value="7">По 7 дней</option>
          <option value="14">По 14 дней</option>
          <option value="30">По 30 дней</option>
          <option value="9999">За весь период</option>
        </Form.Select>
      </Form.Group>
      <Button
        onClick={onSubmit}
        variant="primary"
        className="fs-6"
        disabled={!selectedResearch || isSubmitting}
      >
        <img src={IMAGES.icons.download} alt="" className="me-2" />
        {isSubmitting ? "Загрузка..." : "Скачать"}
      </Button>
    </div>
  );
};
